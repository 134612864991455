@import "../../../styles/variable.module.scss";

.resorceMain {
  @include large_device {
    &[class*="pb-"] {
      padding-bottom: 35px !important;
    }
  }

  @include medium_device {
    margin-bottom: 13px;
  }

  .resource__wrapper {
    .mobbtn{
      @include medium_device {
      margin-top: 30px;
      }
    }
    .mainTitle {
      margin-bottom: 40px;

      @include mobile_device {
        margin-bottom: 18px;
      }

      .h3 {
        max-width: 70%;
        @include medium_device {
          font-size: 32px;
          max-width: 100%;
        }
      }
    }

    .cardWrap {
      position: relative;
      margin: 0 -15px;
      [class*="button-next"],
      [class*="button-prev"] {
        @include extra_large {
          bottom: auto;
          top: 100%;
        }
      }
      [class*="swiper-slide"]{
        height: auto;
        display: flex;
      }

      [class*="button-next"] {
        @media screen and (min-width: 1220px) and (max-width: 1366px) {
          right: -30px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1219px) {
          right: -25px;
        }
      }

      [class*="button-prev"] {
        @media screen and (min-width: 1200px) and (max-width: 1366px) {
          left: -30px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1219px) {
          left: -25px;
        }
      }

      .resourceList {
        display: flex;
        margin-bottom: 30px;
        padding: 0 15px;
        width: 100%;
        a {
          transition: box-shadow 0.3s;
          text-decoration: none;
          display: block;
          width: 100%;
          color: var(--desc-font);
          background: var(--background-color);
          border-radius: 16px;
          border: 1px solid var(--border-color);

          @include medium_device {
            border-radius: 10px;
          }

          &:hover {
            -webkit-box-shadow: 0 0 22px 0 rgb(15 15 15 / 20%);
            box-shadow: 0 0 22px 0 rgb(15 15 15 / 20%);
          }

          &:hover {
            .cardImg {
              span {
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
        }

        .card {
          .cardImg {
            text-align: center;
            position: relative;
            width: 100%;
            border-radius: 16px 16px 0 0;
            overflow: hidden;
            @include medium_device {
              border-radius: 10px;
            }
            span {
              img {
                transition: all .5s ease;
                transform: translateZ(0);
              }
            }
          }

          &:hover {
            -webkit-box-shadow: 0 0 22px 0 rgb(15 15 15 / 20%);
            box-shadow: 0 0 22px 0 rgb(15 15 15 / 20%);
            .cardImg {
              span {
                img {
                  -webkit-transform: scale(1.1);
                  transform: scale(1.1);
                }
              }
            }
          }

          .topicPrmain {
            padding: 20px;

            @include medium_device {
              padding: 15px;
            }

            .topicTag {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -5px -5px 0;
              padding-bottom: 8px;

              .tag {
                margin: 0 5px 5px 0;
                border: 1px solid #2e6af5;
                color: #2e6af5;
                ;
                padding: 6px;
                border-radius: 4px;
                font-size: 14px;
                line-height: 12px;
                font-weight: 500;
                text-transform: uppercase;
                display: unset;
                cursor: pointer;

                @include medium_device {
                  font-size: 13px;
                  line-height: 11px;
                }
              }
            }
          }

          .topicProps {

            h2,
            .title {
              margin-bottom: 20px;
              margin-top: 0;
              font-size: 20px;
              line-height: 1.35;
              font-weight: 600;
              color: inherit;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              text-align: inherit;

              @include medium_device {
                font-size: 20px;
                margin-bottom: 15px;
              }
            }

            .avatar {
              position: relative;
              top: 2px;
              flex-shrink: 0;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
              border: 1px solid var(--border-color);
              text-align: center;

              @include medium_device {
                width: 35px;
                height: 35px;
                top: 0;
              }

              svg {
                margin: auto
              }
            }

            .descriptions {
              display: flex;
              align-items: center;

              .avtarRight {
                display: flex;
                flex-direction: column;
                padding-left: 10px;

                @include medium_device {
                  padding-left: 8px;
                }
              }

              .authName {
                font-weight: 500;
                color: inherit;
              }

              .dateInfo {
                span {
                  &:not(:last-child) {
                    margin-right: 17px;
                    position: relative;

                    &::after {
                      position: absolute;
                      right: -10px;
                      top: 0;
                      bottom: 0;
                      margin: auto 0;
                      background: currentColor;
                      height: 4px;
                      width: 4px;
                      content: "";
                      border-radius: 100%;
                    }
                  }
                }
              }

              span {
                font-size: 14px;
                line-height: 1.66667;
                color: $customFont;

                @include medium_device {
                  font-size: 12px;
                }

                input {
                  margin: 0;
                  color: inherit;
                  z-index: 1;
                  background-image: linear-gradient(#000, #000);
                  background-position: 0 100%;
                  /*OR bottom left*/
                  background-size: 0 2px;
                  background-repeat: no-repeat;
                  transition:
                    background-size 0.3s,
                    background-position 0s 0.3s;
                  /*change after the size immediately*/
                  background-color: transparent;
                  font-weight: 500;
                }
              }
            }
          }

          .name {
            @include fontWeight(font-weight, 600, 700);
            font-size: 18px;
            line-height: 32px;
            color: var(--desc-font);
            margin-top: 16px;
          }
        }
      }
    }
  }
}

[dir="rtl"] {
  .resorceMain {
    .resource__wrapper {
      .cardWrap {
        .avtarRight{
          padding-left: 0 !important;
          padding-right: 10px !important;
        }
        .dateInfo span:not(:last-child){
          margin-right: 0 !important;
          margin-left: 17px !important;
          &::after{
            right: auto !important;
            left: -10px !important;
          }
        }
      }
    }
  }
}

[data-theme="dark"] {
  .card {
    &:hover {
      background: #23262f !important;
    }
  }
}